/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';

export const useAuth = () => {
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [email, setEmail] = useState();
  const [tenant, setTenant] = useState();
  const [error, setError] = useState(); // error from social
  const [authProvider, setAuthProvider] = useState(false); //false if 3rd party
  const [stripeStatus, setStripeStatus] = useState(); //status object for Stripe.
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [accountableAdmin, setAccountableAdmin] = useState(false);
  const [provider, setProvider] = useState(false);
  const [programProvider, setProgramProvider] = useState(false);
  const [program, setProgram] = useState(false);
  const [client, setClient] = useState(false);
  const [siteAdmin, setSiteAdmin] = useState(false);
  const [wsClient, setWsClient] = useState(null);
  const logOutTimer = useRef();

  const login = useCallback((uid, email, tenant, token, expirationDate, provider, status) => {
    setToken(token);
    setEmail(email);
    setTenant(tenant);
    setStripeStatus(status);
    setAuthProvider(provider);
    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12); //12 hour timeout.
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        email: email,
        tenant: tenant,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
        authProvider: provider,
        status: status
      })
    );
    setUserId(uid);
  }, []);

  const updateSettings = useCallback((company, email, stripe) => {
    if (company) setTenant(company);
    if (email) setEmail(email);
    if (stripe) setStripeStatus(stripe);
  }, []);

  const logout = useCallback(() => {
    try {
      setToken(null);
      setTokenExpirationDate(null);
      setUserId(null);
      if (wsClient && wsClient.readyState === 1) {
        wsClient.close(1000, 'Closing connection on logout');
      }
      setWsClient(null);
      setAccountableAdmin(false);
      localStorage.removeItem('userData');
    } catch (err) {
      console.log('why');
    }
  }, [wsClient]);

  const clearError = useCallback((value) => {
    setError(value);
  }, []);

  useEffect(() => {
    //auto login if token exists.
    let failData = Cookies.get('fail');
    if (failData) {
      //social auth returned an error that needs to be displayed
      setError(failData);
      Cookies.set('fail', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7
      });
    }
    let storedData = Cookies.get('auth'); //check for cookie sent by oauth providers
    if (storedData) {
      storedData = JSON.parse(storedData.replace('j:', ''));
      storedData.expiration = new Date(new Date().getTime() + 1000 * 60 * 60); //one hour timeout
      storedData.authProvider = false;
      Cookies.set('auth', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7
      });
    }
    if (!storedData) storedData = JSON.parse(localStorage.getItem('userData')); // convert json to object
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(
        storedData.userId,
        storedData.email,
        storedData.tenant,
        storedData.token,
        new Date(storedData.expiration),
        storedData.authProvider,
        storedData.status
      ); //login
    }
  }, [login]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      clearTimeout(logOutTimer.current); // Clear any existing timeout
      logOutTimer.current = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logOutTimer.current);
    }

    // Cleanup function to clear the timeout when the component unmounts or the effect re-runs
    return () => clearTimeout(logOutTimer.current);
  }, [token, tokenExpirationDate, logout]);

  const message = {
    siteAdmin,
    provider,
    programProvider,
    program,
    accountableAdmin
  };

  const INACTIVITY_TIMEOUT = 3500000; //3600000; // e.g., 1 hour of inactivity
  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
    let inactivityTimer = null;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logout, INACTIVITY_TIMEOUT);
      // Update the timestamp in localStorage
      localStorage.setItem('lastActivity', Date.now().toString());
    };

    // Listen for activity in other tabs
    const handleStorageChange = (event) => {
      if (event.key === 'lastActivity') {
        resetInactivityTimer();
      }
    };

    events.forEach((event) => window.addEventListener(event, resetInactivityTimer));
    window.addEventListener('storage', handleStorageChange);

    // Initialize the timer
    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimer));
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [logout]);

  return {
    token,
    userId,
    email,
    tenant,
    error,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    accountableAdmin,
    siteAdmin,
    setSiteAdmin,
    provider,
    setAccountableAdmin,
    setProvider,
    setProgram,
    program,
    setProgramProvider,
    programProvider,
    setClient,
    client,
    wsClient,
    setWsClient
  };
};
