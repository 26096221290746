import React, {useState} from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";

import LoadingSpinner from "./LoadingSpinner";
import { useHttpClient } from "../hooks/http-hook";
import ErrorModal from "./ErrorModal";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VendorAlerts from "../../pages/VendorAlerts";
import ModalComponent from "./ModalComponent";
import Sms from "../../pages/Sms";
import Tox from "../../pages/Tox";
import ARSFilter from "./ARSFilter";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}));

const NotificationsMenu = (props) => {
  const { className, ...rest } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showBACAlerts, setShowBACAlerts] = useState(false); 
  const [showSMS, setShowSMS] = useState(false);
  const [showTox, setShowTox] = useState(false); 
  const [selectedUser, setSelectedUser] =useState(false); 
  const classes = useStyles();


  const readNotification = async (event) => {
    event.preventDefault();
    let responseData = null; 
    let value = event.currentTarget.value; 
  
    try {
       responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/notification/clearNotifications",
        "POST",
        JSON.stringify({
          id: value
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.auth.token,
        }
      );
      props.render(); 
    } catch (err) {}

  };
  const closeAll = () => {
    setShowBACAlerts(false);
    setShowSMS(false); 
    setSelectedUser(false); 
    setShowTox(false);
  }
  return (
    <React.Fragment>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Team Member"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      {showTox && <ModalComponent open={showTox} close={closeAll} title="Tox Alerts" ChildComponent={Tox} selectedUser={selectedUser} size="md"/>}
      {showBACAlerts && <ModalComponent open={showBACAlerts} close={closeAll} title="BAC Alerts" ChildComponent={VendorAlerts} selectedUser={selectedUser} size="md"/>}
      {showSMS && <ModalComponent open={showSMS} close={closeAll} title="Communication" ChildComponent={Sms} selectedUser={selectedUser} size="md"/>}
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            variant="body1"
          >
            MY NOTIFICATIONS
          </Typography>
          {props.auth.accountableAdmin && (
<ARSFilter auth={props.auth} arsUser={props.arsUser} handleSetArsUser={props.handleSetArsUser} filterByARS={props.filterByARS} arsOnly={true} />
            )}
          <List>
            {props.notifications &&
              props.notifications.map((notification) => (
                <ListItem>
                  <ListItemAvatar>
                    <Button onClick={readNotification} key={notification._id} value={notification._id}>
                      <RemoveCircleIcon color="error" />
                    </Button>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h4">
                        {notification.notification+" "}
                        <Button variant="contained" color="Secondary" onClick={() => {setShowBACAlerts(true); setSelectedUser(notification.User);}}>BAC Records</Button>
                        {"  "}
                        <Button variant="contained" color="Secondary" onClick={() => {setShowTox(true); setSelectedUser(notification.User);}}>Tox Records</Button>
                        {"  "}
                        <Button variant="contained" color="Secondary" onClick={() => {setShowSMS(true); setSelectedUser(notification.User);}}>Communication</Button>
                      </Typography>
                    }
                    secondary={
                      <Typography variant="h5">
                        {new Date(notification.date).toLocaleDateString()}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
          </List>
          {props.notifications.length==0 && <Typography>No Notifications For Now!</Typography>
          }
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default NotificationsMenu;
