import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import logoSrc from "../../../../public/images/white_logo_transparent_background.png";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={logoSrc} height="60px" />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
