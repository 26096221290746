import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import './assets/scss/index.scss';
import LoadingSpinner from './common/UI/LoadingSpinner';
import Routes from './Routes';
import validate from 'validate.js';
import validators from './common/validators';
import { useAuth } from './common/hooks/auth-hook';
import { AuthContext } from './common/context/auth-context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import ReconnectingWebSocket from 'reconnecting-websocket';
import markerSDK from '@marker.io/browser';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);
validate.validators = { ...validate.validators, ...validators };

const App = () => {
  const {
    token,
    userId,
    email,
    tenant,
    error,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    accountableAdmin,
    provider,
    setSiteAdmin,
    siteAdmin,
    setAccountableAdmin,
    setProvider,
    setProgram,
    program,
    setProgramProvider,
    programProvider,
    client,
    setClient,
    setWsClient
  } = useAuth();

  useEffect(() => {
    const loadMarker = async () => {
      try {
        await markerSDK.loadWidget({ project: '656e846bd92a0bae64d9da4a', silent: true });
      } catch (err) {
        console.log('');
      }
    };
    loadMarker();
    return () => {
      markerSDK.unload();
    };
  }, []);

  useEffect(() => {
    // Function to setup WebSocket connection and event listeners
    const setupWebSocket = () => {
      let openCount = 1; //start with 1 so it doesn't evaluate to false.
      const newClient = new ReconnectingWebSocket(process.env.REACT_APP_BACKEND_URL_WS);

      const onOpen = () => {
        newClient.send(
          JSON.stringify({
            event: 'newConnection',
            token: token,
            openCount: openCount++
          })
        );
        setWsClient(newClient);
      };

      const onMessage = (message) => {
        if (message.data === 'PING') {
          setTimeout(() => {
            newClient.send(JSON.stringify({ event: 'PONG', token: token }));
          }, 5000);
        } else {
          if (message?.data.includes('Internal server error')) {
            console.log('socket error');
          } else {
            toast.success(message.data);
            new Audio('https://accountableimages.s3.amazonaws.com/message.mp3').play();
          }
        }
      };

      const onClose = () => {
        newClient.send(JSON.stringify({ event: 'closeConnection', token: token }));
        setWsClient(null);
      };

      newClient.addEventListener('open', onOpen);
      newClient.addEventListener('message', onMessage);
      newClient.addEventListener('close', onClose);
    };

    if (token && accountableAdmin) {
      // Only setup WebSocket connection if token and accountableAdmin conditions are met
      setupWebSocket();
    }
  }, [token, accountableAdmin]); // Dependencies array

  return (
    <Elements stripe={stripePromise}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          userId: userId,
          email: email,
          tenant: tenant,
          error: error,
          token: token,
          login: login,
          logout: logout,
          clearError: clearError,
          updateSettings: updateSettings,
          authProvider: authProvider,
          stripeStatus: stripeStatus,
          accountableAdmin: accountableAdmin,
          provider: provider,
          setSiteAdmin: setSiteAdmin,
          siteAdmin: siteAdmin,
          setAccountableAdmin: setAccountableAdmin,
          setProvider: setProvider,
          setProgram: setProgram,
          program: program,
          setProgramProvider: setProgramProvider,
          programProvider: programProvider,
          client: client,
          setClient: setClient
        }}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Suspense
              fallback={
                <div align="center">
                  <LoadingSpinner open={true} />
                </div>
              }>
              <Routes />
              <Toaster position="top-right" toastOptions={{ duration: 30000 }}>
                {(t) => (
                  <ToastBar toast={t}>
                    {({ icon, message }) => (
                      <>
                        {icon}
                        {message}
                        {t.type !== 'loading' && (
                          <button onClick={() => toast.dismiss(t.id)}>X</button>
                        )}
                      </>
                    )}
                  </ToastBar>
                )}
              </Toaster>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </AuthContext.Provider>
    </Elements>
  );
};

export default App;
