import React, { useContext, useState, useEffect } from "react";
import ErrorModal from "../../../common/UI/ErrorModal";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "../../../common/hooks/form-hook";
import { AuthContext } from "../../../common/context/auth-context";
import LoadingSpinner from "../../../common/UI/LoadingSpinner";
import { useHttpClient } from "../../../common/hooks/http-hook";
import { makeStyles } from "@material-ui/styles";
import { MenuItem, InputLabel, Select, FormControl } from "@material-ui/core";
import {useGetMembers} from "../../../common/hooks/getmembers-hook"; 
import Checkbox from "@material-ui/core/Checkbox";
import { ReportProblemSharp } from "@material-ui/icons";

const schema = {
  toxDate: {
    presence: { allowEmpty: true, message: "is required" },
  },
  Notes: {
    presence: { allowEmpty: true, message: "is required" },
  },

};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddTox = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [toxType, setToxType] = useState(true);
  const {members} = useGetMembers(sendRequest, auth); 
  const [file, setFile] = useState();
  const [result, setResult] = useState(true); 
  const [selfReport, setSelfReport] = useState(false); 
  const [selectedUser, setSelectedUser] = useState(""); 
  const [dnaConfirmation, setDNAConfirmation] = useState(1);

  const handleSelectResultChange = (event) => {
    setResult(event.target.value); 
  };

  useEffect(() => {
    //validate form from getgo.
    try {
      if (props.User) {
        let member = document.querySelector("input[name='member']");
        let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "value"
        ).set;
        nativeInputValueSetter.call(member, "is working");
        let inputEvent = new Event("input", { bubbles: true });
        member.dispatchEvent(inputEvent);

      }
    } catch (err) {}
  }, [open, props.User]);

  useEffect(()=>{
    if (props.User)
    setSelectedUser(props.User.id); 
  },[open, props.User]); 

  const handleSelectChange = (event) => {
    setToxType(event.target.value);
  };

  const hanndleDNAChange = (event) => {
    setDNAConfirmation(event.target.value);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("file", file);
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/accountable/toxes",
        "POST",
        JSON.stringify({
          member: selectedUser,
          toxDate: formState.values.toxDate,
          Notes: formState.values.Notes,
          toxResult:result,
          toxType, 
          selfReport,
          report: formState.values.report,
          dnaConfirmation: dnaConfirmation
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }

      );
        if (file && responseData._id) {
          try {
            formData.append("toxID", responseData._id); 
            const nextMessage = await sendRequest(
              process.env.REACT_APP_BACKEND_URL + "/api/upload/uploadTox",
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
           
          } catch (err) {
            console.log(err);
          }
        }
        setFile(null); 
    } catch (err) {}
    setOpen(false);
    props.close(false);
  };
  const handleCancel = (event) => {
    event.preventDefault();
    setOpen(false);
    setFile(null); 
    props.close(false);
  };

  const handleSelfReport = () => {
    setSelfReport(!selfReport); 
  }

  const handleSelectedUser = (event)=> {
event.preventDefault(); 
setSelectedUser(event.target.value); 
  }
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  return (
    <React.Fragment>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating User"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Add a Tox Result</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details from the toxicology test. The notes should include what the individual tested positive for if anything. 
          </DialogContentText>
          <FormControl className={classes.formControl}>
              <InputLabel labelId="Member">Member</InputLabel>
              <Select
                className={classes.textField}
                error={hasError("member")}
                fullWidth
                helperText={
                  hasError("member") ? formState.errors.member[0] : null
                }
                labelId="Member"
                name="member"
                onChange={handleSelectedUser}
                value={selectedUser}
              >
                {members &&
                  members.map((member) => (
                    <MenuItem value={member._id}>
                      {member.lastName}, {member.firstName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          <TextField
            className={classes.textField}
            error={hasError("toxDate")}
            fullWidth
            helperText={hasError("toxDate") ? formState.errors.toxDate[0] : null}
            label="Toxicology Date"
            name="toxDate"
            onChange={handleChange}
            type="date"
            defaultValue={new Date().toISOString().substr(0,10)}
            value={formState.values.toxDate || ""}
            variant="outlined"
            InputLabelProps={{ shrink: true }}  
          />
        <FormControl className={classes.formControl}>
            <InputLabel id="result-label">Result</InputLabel>
            <Select
              labelId="result-label"
              id="result-select"
              value={result}
              onChange={handleSelectResultChange}
              
            >
              <MenuItem value="Positive">Postitive</MenuItem>
              <MenuItem value="Missed">Missed</MenuItem>
              <MenuItem value="Negative">Negative</MenuItem>
              <MenuItem value="Update">Update</MenuItem>
              <MenuItem value="Collected">Lab Test Collected</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="toxType-label">Tox Type</InputLabel>
            <Select
              labelId="toxType-label"
              id="toxType-select"
              value={toxType}
              onChange={handleSelectChange}
              
            >
              <MenuItem value={1}>Alcohol</MenuItem>
              <MenuItem value={2}>10 Panel</MenuItem>
              <MenuItem value={3}>12 Panel</MenuItem>
              <MenuItem value={4}>14 Panel</MenuItem>
              <MenuItem value={5}>Lab Confirmation</MenuItem>
              <MenuItem value={6}>BAC</MenuItem>
              <MenuItem value={7}>Medication Monitoring</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="toxType-label">DNA Confirmation</InputLabel>
            <Select
              labelId="toxType-label"
              id="toxType-select"
              value={dnaConfirmation}
              onChange={hanndleDNAChange}
              
            >
              <MenuItem value={1}>Not Applicable</MenuItem>
              <MenuItem value={2}>Pass</MenuItem>
              <MenuItem value={3}>Indeterminite</MenuItem>
              <MenuItem value={4}>Fail</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.textField}
            error={hasError("Notes")}
            fullWidth
            helperText={hasError("Notes") ? formState.errors.Notes[0] : null}
            label="Notes"
            name="Notes"
            onChange={handleChange}
            value={formState.values.Notes || ""}
            variant="outlined"
          />
                    <TextField
            className={classes.textField}
            fullWidth
            label="Report File"
            name="file"
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
            type="file"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
                     <FormControl className={classes.formControl}>
            <InputLabel id="completedGoal-label" shrink>
            Was this a self report? 
            </InputLabel>
            <Checkbox
              className={classes.textField}
              error={hasError("restrictedUser")}
              fullWidth
              helperText={
                hasError("restrictedUser") ? formState.errors.selfReport[0] : null
              }
              label="Self Report?"
              name="restrictedUser"
              onChange={handleSelfReport}
              checked={selfReport}
              variant="outlined"
              disabled = {!auth.accountableAdmin}
            />
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            autoFocus
            disabled={!formState.isValid}
          >
            Save Toxicology
          </Button>
          <Button onClick={handleCancel} variant="contained" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddTox;
