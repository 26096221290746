import  { useState, useEffect } from "react";
import {useHttpClient} from './http-hook'; 

export const useConfirmEmail = () => {
  const {sendRequest} = useHttpClient(); 
  const [confirmEmail, setConfirmEmail] = useState();
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  let search = window.location.search; //confirm email
  let params = new URLSearchParams(search);
  let key = params.get("key");
  useEffect(() => {
    if (key && !emailConfirmed) {
      setConfirmEmail("Thank you for confirming your email");
      const verifyEmail = async () => {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/users/confirm?key=" + key
        );
        if (responseData.valid) 
            setEmailConfirmed(true);
        else
            setConfirmEmail("Error Confirming Email. Please Try Again Later"); 
      };

      verifyEmail();
    }
  }, [key, emailConfirmed, sendRequest]);

  const clearConfirmEmail = () => {
    setConfirmEmail();
  };

  return {confirmEmail, clearConfirmEmail};
};
