import React, { useContext } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import Minimal from './layout/Minimal/Minimal';
import Main from './layout/Main/Main';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ProgramSignUp from './pages/ProgramSignup';
/* import NoteSignUp from './pages/NoteSignUp'; */
import { AuthContext } from './common/context/auth-context';

const Dashboard = React.lazy(() => import('./pages/Dashboard')); // lazy load code splitting
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword')); // lazy load code splitting
const ResetPassword = React.lazy(() => import('./pages/ResetPassword')); // lazy load code splitting
const Account = React.lazy(() => import('./pages/Account')); // lazy load code splitting
const Onboarding = React.lazy(() => import('./pages/Onboarding')); // lazy load code splitting
const Tox = React.lazy(() => import('./pages/Tox')); // lazy load code splitting
const AdminPortal = React.lazy(() => import('./pages/AdminPortal')); // lazy load code splitting
const Goal = React.lazy(() => import('./pages/Goals')); // lazy load code splitting
const Users = React.lazy(() => import('./pages/components/account/users'));
const SafetyPlan = React.lazy(() => import('./pages/SafetyPlan'));
const Sms = React.lazy(() => import('./pages/Sms'));
const ScheduleMessages = React.lazy(() => import('./pages/ScheduleMessages'));
const CreateSurvey = React.lazy(() => import('./pages/CreateSurvey'));
const SurveyForm = React.lazy(() => import('./pages/Survey'));
const GoalSurveyForm = React.lazy(() => import('./pages/GoalSurvey'));
const SurveyResults = React.lazy(() => import('./pages/SurveyResponses'));
const VendorAlerts = React.lazy(() => import('./pages/VendorAlerts')); // lazy load code splitting
const GoalLibrary = React.lazy(() => import('./pages/GoalLibrary'));
const MemberReports = React.lazy(() => import('./pages/MemberReports'));
const Reinforcement = React.lazy(() => import('./pages/Reinforcement'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const AccountableGPT = React.lazy(() => import('./pages/AccountableGPT'));
const InsuranceSignUp = React.lazy(() => import('./pages/InsuranceSignUp'));

const Routes = () => {
  const auth = useContext(AuthContext);
  let routes;
  const location = useLocation();
  const history = useHistory();
  const [currentPath, setCurrentPath] = React.useState('');
  const { search } = window.location; // confirm email
  const params = new URLSearchParams(search);
  const key = params.get('key');
  let dashboard = '/dashboard';
  if (key) dashboard = `/dashboard?key=${key}`; // send confirmation key to dashboard if logged in.
  let tenantName = '';
  if (auth.program || auth.programProvider) tenantName = auth.tenant;
  else if (auth.provider) tenantName = auth.email;
  else tenantName = auth.tenant;

  const setCurrentPathCallback = React.useCallback((page) => {
    setCurrentPath(page);
  }, []);

  const redirectToURL = (page) => {
    setCurrentPathCallback(page);
    history.push(`/${page}`);
  };

  // useEffect(() => {
  //   if (!auth.isLoggedIn) {
  //     redirectToURL('sign-in');
  //   }
  //   const newPath = location?.pathname?.substring(1);
  //   if (newPath && (currentPath !== newPath) && currentPath !== '/') {
  //     redirectToURL(newPath);
  //   }
  // }, [currentPath]);

  if (auth.stripeStatus && auth.stripeStatus.status === 'canceled' && auth.isLoggedIn) {
    routes = (
      <Switch>
        <Route
          path="/account"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Account {...props} />
            </Main>
          )}
        />
        <Redirect to="/account?billing=true" />
      </Switch>
    );
  } else if (auth.isLoggedIn) {
    routes = (
      <Switch>
        <Route
          path="/dashboard"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Dashboard {...props} />
            </Main>
          )}
        />
        <Route
          path="/account"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Account {...props} />
            </Main>
          )}
        />
        <Route
          path="/tox"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Tox {...props} />
            </Main>
          )}
        />
        <Route
          path="/vendoralerts"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <VendorAlerts {...props} />
            </Main>
          )}
        />
        <Route
          path="/goal"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Goal {...props} />
            </Main>
          )}
        />
        <Route
          path="/accountableGPT"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <AccountableGPT {...props} />
            </Main>
          )}
        />
        <Route
          path="/adminportal"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <AdminPortal {...props} />
            </Main>
          )}
        />
        <Route
          path="/users"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Users {...props} />
            </Main>
          )}
        />
        <Route
          path="/safety"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <SafetyPlan {...props} />
            </Main>
          )}
        />
        <Route
          path="/sms"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Sms {...props} />
            </Main>
          )}
        />
        <Route
          path="/schedule"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <ScheduleMessages {...props} />
            </Main>
          )}
        />
        <Route
          path="/surveyadmin"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <CreateSurvey {...props} />
            </Main>
          )}
        />
        <Route
          path="/goallibrary"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <GoalLibrary {...props} />
            </Main>
          )}
        />
        <Route
          path="/survey"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <SurveyForm {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/goalsurvey"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <GoalSurveyForm {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/surveyresults"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <SurveyResults {...props} />
            </Main>
          )}
        />
        <Route
          path="/reports"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <MemberReports {...props} />
            </Main>
          )}
        />
        <Route
          path="/analytics"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Analytics {...props} />
            </Main>
          )}
        />
        <Route
          path="/onboarding"
          exact
          render={(props) => (
            <Main tenantname={tenantName}>
              <Onboarding {...props} />
            </Main>
          )}
        />
        <Route
          path="/reinforcement"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <Reinforcement {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/website"
          component={() => {
            window.location.href = 'https://youareaccountable.com/';
            return null;
          }}
        />
        <Route
          path="/scholarships"
          component={() => {
            window.location.href = 'https://youareaccountable.com/scholarships/';
            return null;
          }}
        />
        <Redirect to={dashboard} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route
          path="/sign-in"
          exact
          render={(props) => (
            <Minimal>
              <SignIn {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/sign-up"
          exact
          render={(props) => (
            <Minimal>
              <SignUp {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/program-sign-up"
          exact
          render={(props) => (
            <Minimal>
              <ProgramSignUp {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/insurance"
          exact
          render={(props) => (
            <Minimal>
              <InsuranceSignUp {...props} />
            </Minimal>
          )}
        />
        {/* <Route path = "/note-sign-up" exact render={(props)=> <Minimal><NoteSignUp {...props}/></Minimal>}/> */}
        <Route
          path="/ForgotPassword"
          exact
          render={(props) => (
            <Minimal>
              <ForgotPassword {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/resetpw"
          exact
          render={(props) => (
            <Minimal>
              <ResetPassword {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/survey"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <SurveyForm {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/goalsurvey"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <GoalSurveyForm {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/surveyresults"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <SurveyResults {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/reinforcement"
          exact
          render={(props) => (
            <Minimal tenantname={tenantName}>
              <Reinforcement {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/terms"
          component={() => {
            window.location.href = 'https://youareaccountable.com/terms';
            return null;
          }}
        />
        <Route
          path="/programterms"
          component={() => {
            window.location.href = 'https://youareaccountable.com/terms-of-service-partner-program';
            return null;
          }}
        />
        <Route
          path="/website"
          component={() => {
            window.location.href = 'https://youareaccountable.com/';
            return null;
          }}
        />
        <Route
          path="/scholarships"
          component={() => {
            window.location.href = 'https://youareaccountable.com/scholarships/';
            return null;
          }}
        />
        <Redirect to="/sign-in" />
      </Switch>
    );
  }
  return routes;
};

export default Routes;
