import React, { useContext, useEffect, useState } from 'react';
import ErrorModal from '../common/UI/ErrorModal';
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Grid,
  Button,
  TableBody,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AuthContext } from '../common/context/auth-context';
import LoadingSpinner from '../common/UI/LoadingSpinner';
import { useHttpClient } from '../common/hooks/http-hook';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddTox from './components/toxes/add-tox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import { ChatList } from 'react-chat-elements';
import { useGetFilters } from '../common/hooks/getfilters-hook';
import Filter from '../common/UI/Filter';
import 'react-chat-elements/dist/main.css';
import 'react-vis/dist/style.css';
import InfoIcon from '@material-ui/icons/Info';
import { Button as ReactButton } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import { CSVLink } from 'react-csv';
import favIco from '../../public/images/logo_size.jpg';
import ARSFilter from '../common/UI/ARSFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  add: {
    alignContent: 'right'
  }
}));

const Tox = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [toxes, setToxes] = useState([]);
  const [editTox, setEditTox] = useState(null); //for payer edit button
  const [rerender, setReRender] = useState(false);
  const {
    adminUsers,
    getAdminUsers,
    setSelectedButton,
    selectedButton,
    setFilterText,
    filterText,
    handleSetArsUser,
    arsUser,
    filterByARS
  } = useGetFilters(sendRequest, auth, showModal, false, null, rerender, null, setReRender);
  const [selectedUser, setSelectedUser] = useState();
  const [messageCount, setMessageCount] = useState(1); //how many previous messages to pull
  const [toxType, setToxType] = useState('ALL');
  const [toxResultFilter, setToxResultFilter] = useState('ALL');

  useEffect(() => {
    if (props.selectedUser && adminUsers) {
      setSelectedButton(2); //set to all users
      const user = adminUsers.find((users) => users._id === props.selectedUser);
      if (user) {
        const myUser = {
          alt: user.firstName + ' ' + user.lastName,
          title: user.firstName + ' ' + user.lastName,
          subtitle: user.phoneNumber || 'No Number Entered',
          id: user._id,
          unread: '0'
        };
        setSelectedUser(myUser);
      }
    }
  }, [adminUsers]);

  useEffect(() => {
    //get users for tenant
    const getToxes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/api/accountable/gettoxes',
          'POST',
          JSON.stringify({
            user: selectedUser || null,
            count: messageCount
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData && !responseData.message) {
          let toxes = responseData;
          if (toxResultFilter !== 'ALL')
            toxes = toxes.filter((tox) => tox.toxResult === toxResultFilter);
          if (toxType !== 'ALL') toxes = toxes.filter((tox) => tox.toxType === toxType);
          if (responseData && !responseData.message && (!selectedUser || selectedUser.id === ''))
            setToxes(toxes.sort((a, b) => (a.toxDate < b.toxDate ? 1 : -1)));
          else if (responseData && !responseData.message && selectedUser && selectedUser.id !== '')
            setToxes(toxes.sort((a, b) => (a.toxDate < b.toxDate ? 1 : -1)));
        }
      } catch (err) {}
    };
    getToxes();
  }, [sendRequest, showModal, selectedUser, messageCount, toxType, toxResultFilter]);

  const addTox = (event) => {
    event.preventDefault();
    setEditTox(null);
    setShowModal(true);
  };

  const showEditModal = (event) => {
    setEditTox(event.currentTarget.value);
    setShowModal(true);
  };

  const deleteTox = async (event) => {
    event.preventDefault();
    let target = event.currentTarget.value; //current target gets lost on async.
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/accountable/deletetox',
        'POST',
        JSON.stringify({
          toxid: event.currentTarget.value
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      if (responseData.valid) {
        setToxes(toxes.filter((tox) => tox._id !== target)); //remove deleted object
      }
    } catch (err) {}
  };

  const handleChange = (event) => {
    let target = event.target.value;
    setFilterText(target);
    if (target.length > 2) getAdminUsers(target);
    else if (target.length < (filterText && filterText.length)) getAdminUsers(target);
  };
  const saveSelectedUser = (event) => {
    setSelectedUser(event);
    setMessageCount(1);
  };

  const getMoreResults = (event) => {
    setMessageCount(messageCount + 1);
  };

  const handleFilterByType = async (event) => {
    let value = event.target.value;
    setToxType(value);
  };
  const handleToxResultFilter = async (event) => {
    let value = event.target.value;
    setToxResultFilter(value);
  };

  return (
    <div className={classes.root}>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Tox"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      {(auth.accountableAdmin || auth.provider) && (
        <TextField
          className={classes.textField}
          label="Filter By Last Name"
          name="filter"
          onChange={handleChange}
          variant="outlined"
        />
      )}
      <Grid container spacing={4}>
        {(auth.accountableAdmin || auth.program || auth.programProvider) && (
          <AddTox open={showModal} close={setShowModal} editVal={editTox} User={selectedUser} />
        )}
        {(auth.accountableAdmin || auth.provider || auth.programProvider || auth.program) &&
          !props.selectedUser && (
            <Grid item lg={3} sm={12} xl={3} xs={12}>
              <Card>
                <CardContent>
                  {(auth.accountableAdmin || auth.provider) && (
                    <>
                      <Filter
                        selectedButton={selectedButton}
                        setSelectedButton={setSelectedButton}
                        authContext={auth}
                        sms
                      />
                      <Typography variant="h4" color="primary">
                        Members
                      </Typography>
                      {auth.accountableAdmin && (
                        <ARSFilter
                          auth={auth}
                          arsUser={arsUser}
                          handleSetArsUser={handleSetArsUser}
                          filterByARS={filterByARS}
                        />
                      )}
                    </>
                  )}
                  <ChatList
                    onClick={saveSelectedUser}
                    className="chat-list"
                    dataSource={adminUsers.map((user) => {
                      return {
                        avatar: favIco,
                        alt: user.firstName + ' ' + user.lastName,
                        title: user.firstName + ' ' + user.lastName,
                        subtitle: user.phoneNumber || 'No Number Entered',
                        meds: user.medications,
                        harmReductionNotes: user.harmReductionNotes,
                        id: user._id,
                        unread: '0'
                      };
                    })}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        <Grid
          item
          lg={props.selectedUser ? 12 : 8}
          sm={12}
          xl={props.selectedUser ? 12 : 8}
          xs={12}>
          <Card>
            <CardContent>
              {(auth.accountableAdmin || auth.provider) && !selectedUser && (
                <Typography
                  gutterBottom
                  className={classes.title}
                  color="textSecondary"
                  variant="h3">
                  Please Select a User
                </Typography>
              )}
              {(selectedUser || (!auth.accountableAdmin && !auth.provider)) && (
                <Typography
                  gutterBottom
                  className={classes.title}
                  color="textSecondary"
                  variant="h3">
                  {selectedUser ? selectedUser.title : auth.tenant}'s Toxicology Results
                </Typography>
              )}
              <Typography variant="h6" gutterBottom>
                Toxicologies act as a safety measure for you and your loved ones. It reduces your
                families/support systems worries about needing to monitor you and allows you to not
                feel overwhelmed in your own home. It allows you to work on communication and
                growing along your path of recovery together. Toxicologies also serve as a way to
                see your progress - you will be able to see how far you have come and it is a
                testament to all of the hard work that you have been doing.{' '}
              </Typography>
              {selectedUser &&
                (selectedUser.meds || selectedUser.harmReductionNotes) &&
                auth.accountableAdmin && <br />}
              {selectedUser &&
                selectedUser.meds &&
                selectedUser.meds.length > 0 &&
                auth.accountableAdmin && (
                  <Typography variant="h4" gutterBottom>
                    Current Medications: {selectedUser.meds.join(', ')}
                  </Typography>
                )}
              {selectedUser && auth.accountableAdmin && selectedUser.harmReductionNotes && (
                <Typography variant="h4" gutterBottom>
                  Harm Reduction Parameters: {selectedUser.harmReductionNotes}
                </Typography>
              )}
              <Grid container justify="flex-end">
                {(auth.accountableAdmin || auth.programProvider || auth.program) && (
                  <Button color="secondary" onClick={addTox}>
                    Add Tox&nbsp;&nbsp;
                    <AddCircleIcon color="secondary" fontSize="small" />
                  </Button>
                )}
                <CSVLink
                  filename={'ToxResults' + new Date().toUTCString() + '.csv'}
                  data={toxes.map((tox) => {
                    const newObj = {};
                    newObj.Member = selectedUser ? selectedUser.title : auth.tenant;
                    newObj.Date = tox.toxDate;
                    newObj.Result = tox.toxResult;
                    newObj.Notes = tox.Notes;
                    let toxValue = '';
                    switch (tox.toxType) {
                      case 1:
                        toxValue = 'Alcohol';
                        break;
                      case 2:
                        toxValue = '10 Panel';
                        break;
                      case 3:
                        toxValue = '12 Panel';
                        break;
                      case 4:
                        toxValue = '14 Panel';
                        break;
                      case 5:
                        toxValue = 'Lab Confirmation';
                        break;
                      case 6:
                        toxValue = 'Breathalyzer';
                        break;
                      case 7:
                        toxValue = 'Medication Monitoring';
                        break;
                    }
                    newObj.Type = toxValue;
                    return newObj;
                  })}>
                  <ReactButton variant="contained" color="secondary">
                    Download Export
                  </ReactButton>
                </CSVLink>
              </Grid>
              <Grid container justify="center">
                <ReactButton color="primary" onClick={getMoreResults} variant="contained">
                  See Prior Results&nbsp;&nbsp;
                  <LoopIcon color="secondary" fontSize="small" />
                </ReactButton>
              </Grid>
              <Grid container justifyContent="space-evenly">
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="case-label">Filter by Tox Type</InputLabel>
                    <Select
                      labelId="case-label"
                      id="case-select"
                      value={toxType || 'ALL'}
                      onChange={handleFilterByType}>
                      <MenuItem value={'ALL'} key={'ALL'}>
                        Show All
                      </MenuItem>
                      <MenuItem value={2} key={2}>
                        10 Panel
                      </MenuItem>
                      <MenuItem value={3} key={3}>
                        12 Panel
                      </MenuItem>
                      <MenuItem value={4} key={4}>
                        14 Panel
                      </MenuItem>
                      <MenuItem value={5} key={5}>
                        Lab Confirmation
                      </MenuItem>
                      <MenuItem value={6} key={6}>
                        Breathalyzer
                      </MenuItem>
                      <MenuItem value={7} key={7}>
                        Medication Monitoring
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="case-label">Filter by Tox Result</InputLabel>
                    <Select
                      labelId="case-label"
                      id="case-select"
                      value={toxResultFilter || 'ALL'}
                      onChange={handleToxResultFilter}>
                      <MenuItem value={'ALL'} key={'ALL'}>
                        Show All
                      </MenuItem>
                      <MenuItem value={'Positive'} key={2}>
                        Positive
                      </MenuItem>
                      <MenuItem value={'Missed'} key={3}>
                        Missed
                      </MenuItem>
                      <MenuItem value={'Update'} key={4}>
                        Update
                      </MenuItem>
                      <MenuItem value={'Negative'} key={5}>
                        Negative
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {(auth.accountableAdmin || auth.provider) && <TableCell>Last Name</TableCell>}
                      {(auth.accountableAdmin || auth.provider) && (
                        <TableCell>First Name</TableCell>
                      )}
                      <TableCell>Tox Date</TableCell>
                      <TableCell>Tox Result</TableCell>
                      <TableCell>Tox Type</TableCell>
                      <TableCell>DNA Confirmation</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Report</TableCell>
                      {auth.accountableAdmin && false && <TableCell>Edit</TableCell>}
                      {(auth.accountableAdmin || auth.program || auth.programProvider) && (
                        <TableCell>Delete</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(((auth.accountableAdmin || auth.provider) && selectedUser) ||
                      (!auth.accountableAdmin && !auth.provider)) &&
                      toxes &&
                      toxes.map((tox) => {
                        let toxValue = '';
                        switch (tox.toxType) {
                          case 1:
                            toxValue = 'Alcohol';
                            break;
                          case 2:
                            toxValue = '10 Panel';
                            break;
                          case 3:
                            toxValue = '12 Panel';
                            break;
                          case 4:
                            toxValue = '14 Panel';
                            break;
                          case 5:
                            toxValue = 'Lab Confirmation';
                            break;
                          case 6:
                            toxValue = 'Breathalyzer';
                            break;
                        }
                        let dnaConfirmation = 'Not Set';
                        if (tox.dnaConfirmation)
                          switch (tox.dnaConfirmation) {
                            case '1':
                              dnaConfirmation = 'Not Applicable';
                              break;
                            case '2':
                              dnaConfirmation = 'Pass';
                              break;
                            case '3':
                              dnaConfirmation = 'Indeterminate';
                              break;
                            case '4':
                              dnaConfirmation = 'Fail';
                              break;
                          }
                        return (
                          <TableRow key={tox._id}>
                            {(auth.accountableAdmin || auth.provider) && (
                              <TableCell>{tox.User.lastName}</TableCell>
                            )}
                            {(auth.accountableAdmin || auth.provider) && (
                              <TableCell>{tox.User.firstName}</TableCell>
                            )}
                            <TableCell>
                              {new Date(tox.toxDate).getUTCMonth() + 1}/
                              {new Date(tox.toxDate).getUTCDate()}/
                              {new Date(tox.toxDate).getFullYear()}
                            </TableCell>
                            <TableCell>{tox.toxResult}</TableCell>
                            <TableCell>{toxValue}</TableCell>
                            <TableCell>{dnaConfirmation}</TableCell>
                            <TableCell>{tox.Notes}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                disabled={!tox.report}
                                onClick={() => {
                                  window.open(tox.report, '_blank');
                                }}>
                                <InfoIcon />
                              </Button>
                            </TableCell>
                            {auth.accountableAdmin && false && (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  value={tox._id}
                                  key={tox._id}
                                  onClick={showEditModal}>
                                  <EditIcon></EditIcon>
                                </Button>
                              </TableCell>
                            )}
                            {(auth.accountableAdmin || auth.program || auth.programProvider) && (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  value={tox._id}
                                  key={tox._id}
                                  onClick={deleteTox}>
                                  <DeleteIcon></DeleteIcon>
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Tox;
