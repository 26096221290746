/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors } from "@material-ui/core";
import { AuthContext } from "../../../common/context/auth-context";
const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;
  const auth = useContext(AuthContext);
  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
  };

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
    { pages.map((page) => (
      <ListItem className={classes.item} disableGutters key={page.title}>
      { page.logout ? 
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href} onClick={logoutHandler} >
          <div className={classes.icon}>{page.icon}</div>
          {page.title}
        </Button>
      : 
      <>
        { !page.logout && ((!page.User && (!page.hide || auth.accountableAdmin || auth.provider)) ||
          (page.User && !auth.accountableAdmin && !auth.provider)) && (!page.admin || auth.accountableAdmin) &&
          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href} >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        }
        { (!page.logout && ((page.Program && auth.program) || (page.siteAdmin && auth.siteAdmin))) ?
          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={page.href} >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button> : null
        }
      </>
      }
      </ListItem>
    ))}
    </List>
  );
};

export default SidebarNav;
