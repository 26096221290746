import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer, Typography } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import InputIcon from "@material-ui/icons/Input";
import FavoriteIcon from '@material-ui/icons/Favorite';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SmsIcon from '@material-ui/icons/Sms';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SidebarNav from "./SidebarNav";
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PieChartIcon from '@material-ui/icons/PieChart';
import PeopleRounded from "@material-ui/icons/PeopleRounded";


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flexF",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  tenant: {
    marginLeft: theme.spacing(1)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();

  const pages = [
    {
      title: "My Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: 'Outcomes Analytics',
      href: '/analytics',
      icon: <PieChartIcon />,
      admin:true,
      Program:true,
      siteAdmin:true,
       
    },
    {
      title: 'Onboarding',
      href: '/onboarding',
      icon: <PeopleRounded />,
      admin:true
    },
    {
      title: 'Ask AJ',
      href: '/accountableGPT',
      icon: <LiveHelpIcon />,
      admin:true,
    },
    {
    title: 'Member Reports',
    href: '/reports',
    icon: <AssessmentIcon />,
    admin:true 
  },
    {
      title: 'Wellness Goal Plan',
      href: '/goal',
      icon: <CheckCircleIcon />,
    },
    {
      title: 'Goal Library',
      href: '/goallibrary',
      icon: <LocalLibraryIcon />,
    },
    {
      title: 'Safety Plan',
      href: '/safety',
      icon: <ReportProblemIcon />,
      User:true
    },
   {
      title: 'Communication Log',
      href: '/sms',
      icon: <SmsIcon />,
      
    },
    {
      title: 'Survey Results',
      href: '/surveyresults',
      icon: <ShowChartIcon />,
      
    },

    {
      title: 'Scheduled Messages',
      href: '/schedule',
      icon: <AccessAlarmIcon />,
      admin:true 
    },
    {
      title: 'Manage Surveys',
      href: '/surveyadmin',
      icon: <LiveHelpIcon />,
      admin:true 
    },
     {
      title: 'Toxicology Results',
      href: '/tox',
      icon: <FavoriteIcon />
    },
    {
      title: 'Breathalyzer Results',
      href: '/vendoralerts',
      icon: <RecordVoiceOverIcon />
    },
    {
      title: 'My Members',
      href: '/adminportal',
      icon: <PeopleIcon />,
      hide:true 
    },
    {
      title: 'Care Team Members',
      href: '/Users',
      icon: <PeopleIcon />,
      User:true,
      Program:true,
      siteAdmin:true,
       
    },
    {
      title: "Account Settings",
      href: "/account",
      icon: <AccountBoxIcon />,
    },
    {
      title: "Logout",
      href: "/sign-in",
      logout: true,
      icon: <InputIcon />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Typography align="left" variant="h5" className={classes.tenant}>
          {props.tenantname}
        </Typography>
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

export default Sidebar;
