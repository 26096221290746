/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';

export const useGetFilters = (
  sendRequest,
  auth,
  showModal,
  isSMS,
  url,
  rerender,
  filterOutDx,
  setReRender
) => {
  const [ARS, setARS] = useState();
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedButton, setSelectedButton] = useState(auth.accountableAdmin ? 1 : 2); //start with ARS
  const [filterText, setFilterText] = useState();
  const [filterByARS, setFilterByARS] = useState();
  const [arsFilter, setARSFilter] = useState();
  const [programFilter, setProgramFilter] = useState();
  const [sortFilter, setSortFilter] = useState(); //1 Lab Screens Ascending, 2 Lab Screens Descending, 3 Last Name Ascending, 4 Last Name Descending, 5 tox screens ascending, 6 tox screens descending
  const [arsUser, setARSUser] = useState(0);

  //get ars
  useEffect(() => {
    //get permission level for protected subpages.
    const getARS = async () => {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/accountable/adminusersfilter',
        'GET',
        null,
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      if (responseData) {
        setARS(responseData);
        setFilterByARS(responseData);
      }
    };
    if (auth.accountableAdmin) getARS();
  }, []);

  //get admin users
  useEffect(() => {
    //get users for tenant
    const getAdminUsers = async () => {
      try {
        if (!url) url = '/api/accountable/adminusers';
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + url,
          'GET',
          null,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData && !responseData.message) {
          if (filterText || filterText === '')
            setAdminUsers(
              adjustFilter(
                responseData
                  .filter((users) =>
                    users.lastName.toLowerCase().includes(filterText.toLowerCase())
                  )
                  .sort((a, b) =>
                    a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                    b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                      ? 1
                      : -1
                  )
              )
            );
          else
            setAdminUsers(
              adjustFilter(
                responseData.sort((a, b) =>
                  a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                  b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                    ? 1
                    : -1
                )
              )
            );
        }
      } catch (err) {}
    };
    if (!isSMS && (auth.accountableAdmin || auth.provider || auth.siteAdmin)) getAdminUsers();
    else if (auth.accountableAdmin || auth.provider || auth.siteAdmin) {
      const getAdminUsers = async () => {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL +
              '/api/accountable/adminuserssms?' +
              new URLSearchParams({
                filter: selectedButton
              }),
            'GET',
            null,
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          if (responseData && !responseData.message)
            setAdminUsers(
              adjustFilter(
                responseData
                  .filter((user) => user.phoneNumber)
                  .sort((a, b) =>
                    a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                    b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                      ? 1
                      : -1
                  )
              )
            ); //only show users with active phone numbers
        } catch (err) {}
      };
      if (auth.accountableAdmin || auth.provider || auth.siteAdmin) getAdminUsers();
    }
  }, [sendRequest, showModal, selectedButton, rerender]);

  const getAdminUsers = async (event) => {
    console.log('in get admin users');
    if (!isSMS)
      try {
        if (!url) url = '/api/accountable/adminusers';
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + url,
          'GET',
          null,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData && !responseData.message)
          if (!event || event.length < 2) {
            setAdminUsers(
              adjustFilter(
                responseData
                  .filter((users) => users.lastName.toLowerCase())
                  .sort((a, b) =>
                    a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                    b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                      ? 1
                      : -1
                  )
              )
            );
          } else {
            setAdminUsers(
              adjustFilter(
                responseData
                  .filter((users) => users.lastName.toLowerCase().includes(event.toLowerCase()))
                  .sort((a, b) =>
                    a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                    b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                      ? 1
                      : -1
                  )
              )
            );
          }
      } catch (err) {
        console.log('is something breaking?');
      }
    if (isSMS)
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            '/api/accountable/adminuserssms?' +
            new URLSearchParams({
              filter: selectedButton
            }),
          'GET',
          null,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData && !responseData.message) {
          if (!event || event.length < 2) {
            setAdminUsers(
              adjustFilter(
                responseData
                  .filter((user) => user.phoneNumber)
                  .sort((a, b) =>
                    a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                    b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                      ? 1
                      : -1
                  )
              )
            );
          } else {
            setAdminUsers(
              adjustFilter(
                responseData
                  .filter((user) => user.phoneNumber)
                  .filter((users) => users.lastName.toLowerCase().includes(event.toLowerCase()))
                  .sort((a, b) =>
                    a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
                    b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
                      ? 1
                      : -1
                  )
              )
            );
          }
        }
      } catch (err) {
        console.log('is something breaking? ' + err);
      }
  };

  const getDifferenceBetweenDates = (date1, date2) => {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  };

  const adjustFilter = (users) => {
    switch (parseInt(selectedButton)) {
      case 0:
        users = users.filter((user) => !user.provider && !user.accountableAdmin);
        break;
      case 1:
        users = users.filter(
          (user) =>
            user.ARS === auth.userId &&
            !user.provider &&
            !user.accountableAdmin &&
            (!user.dischargeDate || new Date(user.dischargeDate) > new Date())
        );

        break;
      case 2:
        users = users.filter(
          (user) =>
            (!user.dischargeDate || new Date(user.dischargeDate) > new Date()) &&
            !user.provider &&
            !user.accountableAdmin &&
            user.stripeStatus !== 'trialing'
        );
        break;
      case 3:
        users = users.filter(
          (user) =>
            new Date(user.dischargeDate) < new Date() && !user.provider && !user.accountableAdmin
        );

        break;

      case 4:
        users = users.filter((user) => user.provider);
        break;
      case 5:
        users = users.filter((user) => user.accountableAdmin);
        break;
      case 6:
        users = users.filter(
          (user) =>
            user.stripeStatus === 'trialing' &&
            (!user.dischargeDate || new Date(user.dischargeDate) > new Date())
        );
        break;
      case 7:
        users = users.filter((user) => user.program);
        break;
    }

    if (arsFilter) {
      if (arsFilter === 'UNASSIGNED')
        users = users.filter((user) => !user.ARS && !user.partnerManaged);
      else if (arsFilter === 'PROGRAMMANAGED') users = users.filter((user) => user.partnerManaged);
      else if (arsFilter === 'NOTPROGRAMMANAGED')
        users = users.filter((user) => !user.partnerManaged);
      else if (arsFilter === 'RECENTINTAKE')
        users = users.filter(
          (user) => getDifferenceBetweenDates(new Date(user.intakeDate), new Date()) <= 10
        );
      else if (arsFilter === 'RECENTREGISTER')
        users = users.filter(
          (user) => getDifferenceBetweenDates(new Date(user.startDate), new Date()) <= 10
        );
      else if (arsFilter === 'RECENTDX')
        users = users.filter(
          (user) =>
            getDifferenceBetweenDates(new Date(user.dischargeDate), new Date()) <= 10 &&
            user.intakeDate
        );
      else users = users.filter((user) => user.ARS === arsFilter);
    }
    if (programFilter) {
      users = users.filter((user) => user.programTenant === programFilter);
    }
    if (filterOutDx) {
      users = users.filter((user) => !user.dxFromProgram);
    }

    switch (
      sortFilter //1 Lab Screens Ascending, 2 Lab Screens Descending, 3 Last Name Ascending, 4 Last Name Descending, 5 tox screens ascending, 6 tox screens descending
    ) {
      case 1:
        users = users.sort((a, b) => {
          if (a.labScreens == null) return 1;
          if (b.labScreens == null) return -1;
          return a.labScreens - b.labScreens;
        });
        break;
      case 2:
        users = users.sort((a, b) => {
          if (a.labScreens == null) return 1;
          if (b.labScreens == null) return -1;
          return b.labScreens - a.labScreens;
        });
        break;
      case 3:
        users = users.sort((a, b) =>
          a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id >
          b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
            ? 1
            : -1
        );
        break;
      case 4:
        users = users.sort((a, b) =>
          a.lastName.toLowerCase() + a.firstName.toLowerCase() + a._id <
          b.lastName.toLowerCase() + b.firstName.toLowerCase() + a._id
            ? 1
            : -1
        );
        break;
      case 5:
        users = users.sort((a, b) => {
          if (a.toxes == null) return 1;
          if (b.toxes == null) return -1;
          return a.toxes - b.toxes;
        });
        break;
      case 6:
        users = users.sort((a, b) => {
          if (a.toxes == null) return 1;
          if (b.toxes == null) return -1;
          return b.toxes - a.toxes;
        });
        break;
    }

    return users;
  };
  const handleSetArsUser = (event) => {
    setARSUser(event.target.value);
    setARSFilter(event.target.value);
    setReRender(!rerender);
  };

  return {
    ARS,
    adminUsers,
    setAdminUsers,
    getAdminUsers,
    selectedButton,
    setSelectedButton,
    adjustFilter,
    setFilterText,
    arsFilter,
    setARSFilter,
    programFilter,
    setProgramFilter,
    setSortFilter,
    sortFilter,
    filterByARS,
    setFilterByARS,
    handleSetArsUser,
    arsUser,
    setARSUser,
    filterText
  };
};
