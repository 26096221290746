import {createContext} from 'react';

export const AuthContext = createContext({
    isLoggedIn: false, 
    userId: null,
    email: null,
    tenant: null,
    error: null,
    siteAdmin: false,
    authProvider: null, 
    stripeStatus: null, 
    login: ()=>{}, 
    logout: ()=>{},
    clearError: ()=>{},
    updateSettings: ()=>{},
    accountableAdmin: false,
    provider: false, 
    setAccountableAdmin: null,
    setProvider: null,
    program: false,
    programProvider: false, 
    setProgram: ()=>{},
    setProgramProvider: ()=>{},
    client: null,
    setClient: ()=>{}
});

