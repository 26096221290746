import React from 'react'; 
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'; 

const ModalComponent = ({size, open, title, fullWidth, variant, close, buttonTitle, ChildComponent, ...otherProps}) => {

    const handleClose = () => {
        close();  
    }
    return (
        <React.Fragment>
            <Dialog open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true} 
            maxWidth={size||"sm"}
            >
            <DialogTitle>{title}</DialogTitle>
             <DialogContent>
                     <ChildComponent {...otherProps}/>
             </DialogContent>
             <DialogActions>
                 <Button onClick={handleClose} fullWidth={fullWidth || false} variant={variant || "text"} color="primary" autoFocus>{buttonTitle ||"Close"} </Button>
             </DialogActions>
            </Dialog>
        </React.Fragment>
    );

}
export default ModalComponent; 