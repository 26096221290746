import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography,
  Popover,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import { AuthContext } from "../../../common/context/auth-context";
import NotificationsMenu from "../../../common/UI/NotificationMenu";
import LoadingSpinner from "../../../common/UI/LoadingSpinner";
import { useHttpClient } from "../../../common/hooks/http-hook";
import ErrorModal from "../../../common/UI/ErrorModal";
import { useNotifications } from "../../../common/hooks/notifications-hook";
import logoSrc from "../../../../public/images/white_logo_transparent_background.png";
const useStyles = makeStyles((theme) => ({
  root: { boxShadow: "none" },
  flexGrow: { flexGrow: 1 },
  signOutButton: { marginLeft: theme.spacing(1) },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const { notifications, reRender, auth, isLoading, error, clearError } =
    useNotifications();
  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
  };
  const showNotifications = (event) => setAnchor(event.currentTarget);
  const handleClose = (event) => setAnchor(null);

  return (
    <React.Fragment>
      {" "}
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Team Member"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Popover
        open={anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <NotificationsMenu
          notifications={notifications}
          auth={auth}
          render={reRender}
        />
      </Popover>
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          <RouterLink to="/">
            <picture></picture>
            <img alt="Logo" src={logoSrc} height="60px" />
          </RouterLink>
          <div className={classes.flexGrow} />
          <Hidden mdDown>
            <IconButton color="inherit" onClick={showNotifications}>
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={logoutHandler}
            >
              <InputIcon />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Topbar;
