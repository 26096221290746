import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";

Sentry.init({
  dsn: "https://67b1cacd2d686829074e78d62ced5e05@o4506593884831744.ingest.sentry.io/4506594441887744",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({ maskAllText: false, blockAllMedia: false }),
  ],
  tracesSampleRate: 0.1, 
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1, 
  environment: process.env.ENV ? process.env.ENV : "production",
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={"An error has occurred"}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
