import { useState, useEffect } from "react";

export const useGetMembers = (sendRequest, auth, showOnlyNumbers) => {
  const [members, setMembers] = useState();

  useEffect(() => {
    //get permission level for protected subpages.
    const getUsers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/accountable/users",
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        if (!showOnlyNumbers)
          setMembers(
            responseData.sort((a, b) =>
              a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
            )
          );
        else
          setMembers(
            responseData
              .filter((user) => user.phoneNumber)
              .sort((a, b) =>
                a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
              )
          );
      } catch (err) {}
    };

    getUsers();
  }, []);
  return { members };
};
